<template>
    <div class="input-wrapper" style="padding: 3px; position: relative;"
         v-click-outside="onClickOutside"
    >
        <template v-if="text_field">
            <v-text-field
                type="text"
                v-model="local_value"
                @focusin="setCoordinates"
                @input="onInput"
                :class="custom_text_field ? 'custom-text-field' : ''"
                hide-details
                required
                color="grey"
                :readonly="readonly"
                :placeholder="placeholder"
                :label="label"
                :filled="filled"
            />
        </template>
        <template v-else>
            <input type="text" v-model="local_value"
                   @keydown.enter="doSelect({id: selected.flat_id, full_address: selected.text, emit: true, once: true})"
                   :class="{'input-thin': thin}"
                   :style="input_style"
                   @focusin="setCoordinates"
                   @input="onInput">
        </template>
        <v-menu v-model="show_drop_down"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
                transition="slide-y-transition"
        >
            <v-card
                flat
                class="select-wrapper elevation-5 transition-fast-in-fast-out"
                v-if="show_drop_down"
                v-scroll.self="infiniteScrolling"
            >
                <div>
                    <v-list dense v-if="items.length">
                        <v-list-item
                            v-for="(elem, index) in items"
                            :key="index"
                            class="pl-2 pr-8 text-break"
                            @click.stop="doSelect(Object.assign({}, elem, {emit: true}))"
                            style="border-bottom: 1px solid #d4d4d4; border-radius: 0; height: 46px">
                            <v-list-item-content class="list-content" v-html="highlightSearch(elem.search_key)"/>
                        </v-list-item>
                    </v-list>
                    <v-list v-else dense>
                        <v-list-item class="pa-2 align-center">
                            <v-list-item-icon class="mr-5 ml-3 align-self-center">
                                <v-icon size="43" color="error lighten-1">
                                    mdi-emoticon-sad-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="font-weight-medium px-3">
                                <v-list-item-title style="font-size: .94rem">
                                    Нічого не знайшли...
                                </v-list-item-title>
                                <v-list-item-subtitle class="pt-1">
                                    спробуйте ввести щось інше
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import flatAPI from "@/utils/axios/flat"

export default {
    name: "AUC_FlatSearchLegacy",
    computed: {
        local_value: {
            set(value) {
                this.local_data = value
            },
            get() {
                return this.local_data === null ? this.text_value : this.local_data
            }
        }
    },
    props: {
        text_field: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        custom_text_field: {
            type: Boolean,
            default: true
        },
        flat_id: {
            type: Number
        },
        text_value: {
            type: String
        },
        thin: {
            type: Boolean,
            default: false
        },
        input_style: {
            type: String,
            default: ''
        },
        watch_clear: {
            type: Boolean,
            default: false
        },
        clear: {
            type: Boolean,
            default: false
        },
        row_num: {
            type: Number,
            default: null
        },
        text_value_key: {
            type: String,
            default: 'full_address'
        }
    },
    data() {
        return {
            x: 0,
            y: 0,
            local_data: null,
            show_drop_down: false,
            items: [],
            page: 1,
            loading: false,
            page_loaded: 1,
            selected: {
                flat_id: null,
                text: null,
                person_id: null,
                search_key: null,
                address: null,
                contractor_name: null,
                contract_name: null,
                contract_id: null
            },
            clear_watcher: null,
        }
    },
    methods: {
        setCoordinates(e) {
            const coordinates = e.target.getBoundingClientRect()
            this.x = Math.ceil(coordinates.x)
            this.y = Math.ceil(coordinates.y) + 30
        },
        watch_clear_func() {
            this.clear_watcher = this.$watch(
                'clear',
                {
                    deep: true,
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.local_data = ""
                            this.doSelect({emit: false})
                            this.$emit('afterClear')
                        }
                    }
                }
            )
        },
        onInput() {
            this.page = 1
            this.page_loaded = 1

            if (this.local_value.length >= 1) {
                flatAPI.search_new_legacy({text: this.local_value, page: this.page})
                    .then(response => response.data)
                    .then(data => {
                        this.items = data
                        this.show_drop_down = true
                    })
            } else {
                this.show_drop_down = false
            }
        },
        infiniteScrolling(e) {
            const height = 400
            const by_page = 15
            const first = e.target.getElementsByClassName('v-list-item') || []
            const row_height = (first[0] || {}).offsetHeight || 48
            const offset = e.target.scrollTop
            const visible_count = Math.floor(height / row_height)
            const current_visible = Math.ceil((offset - 8) / row_height) + (by_page - visible_count) + 2
            let current_page = Math.ceil(current_visible / by_page)

            setTimeout(() => {
                if (current_page > this.page && current_page > this.page_loaded && !this.loading) {
                    this.loading = true
                    this.page++
                    this.page_loaded++
                    flatAPI.search_new_legacy({text: this.local_value, page: current_page})
                        .then(response => response.data)
                        .then(data => {
                            data.forEach(item => this.items.push(item))
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            }, 900);
        },
        doSelect(payload_) {
            let payload = {...payload_}
            if (payload.once) {
                if (this.items.length === 1) {
                    payload = {...this.items[0], emit: payload_.emit}
                }
            }

            const person_id_key = payload['id'] ? 'id' : 'value'
            const text_key = payload['id'] ? this.text_value_key : 'value'
            const search_key = payload['id'] ? 'search_key' : 'value'
            const address_key = payload['id'] ? 'full_address' : 'text'
            const emit = payload['emit']

            this.$nextTick(() => {
                if (this.local_value === "") {
                    this.selected.flat_id = null
                    this.selected.text = ""
                    this.selected.person_id = ""
                    this.selected.search_key = ""
                    this.selected.address = ""
                    this.selected.contractor_name = ""
                    this.selected.contract_name = ""
                    this.selected.contract_id = null
                } else {
                    this.selected.flat_id = (payload || {})[person_id_key] || null
                    this.selected.text = (payload || {})[text_key] || null
                    this.selected.person_id = (payload || {})['person_id'] || null
                    this.selected.search_key = (payload || {})[search_key] || null
                    this.selected.address = (payload || {})[address_key] || null
                    this.selected.contract_name = (payload || {})['contract_name'] || null
                    this.selected.contract_id = (payload || {})['contract_id'] || null
                    this.selected.contractor_name = (payload || {})['contractor_name'] || null

                    this.show_drop_down = false
                    this.local_data = this.selected.text
                }

                if (emit) {
                    this.$emit('selectChange',
                        Object.assign(
                            {},
                        {
                            row_num: this.row_num,
                            value: this.selected.flat_id,
                            text: this.selected.text,
                            person_id: this.selected.person_id,
                            search_key: this.selected.search_key,
                            address: this.selected.address,
                            contract_name: this.selected.contract_name,
                            contract_id: this.selected.contract_id,
                            contractor_name: this.selected.contractor_name
                        })
                    )
                }
            })
        },
        onClickOutside() {
            this.show_drop_down = false
            if (this.selected.flat_id) {
                this.local_data = this.selected.text
            } else {
                this.local_data = this.text_value
                this.selected.text = ""
                this.selected.flat_id = null
                this.selected.person_id = ""
                this.selected.search_key = ""
                this.selected.address = ""
                this.selected.contract_name = ""
                this.selected.contract_id = null
                this.selected.contractor_name = ""
            }
        },
        highlightSearch(text) {
            if (!text) return ''
            if (this.show_drop_down) {
                return text.replace(new RegExp(this.local_value, "gi"), "<strong>" + this.local_value + "</strong>");
            } else {
                return text
            }
        },
    },
    created() {
        if (this.watch_clear) {
            this.watch_clear_func()
        }
    },
    beforeDestroy() {
        if (this.clear_watcher) {
            this.clear_watcher()
        }
    }
}
</script>

<style scoped lang="scss">
input {
  width: 84%;
  display: block;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, .2);
  padding: 2px 4px 2px 0;
  transition: all .2s ease-in-out;

  &:focus {
    border-color: var(--v-success-base);
    font-weight: 500;
  }
}

.input-thin {
  border-bottom: 1px solid rgba(0, 0, 0, .2) !important;
}

.error-input {
  input {
    border-bottom: 2px solid #f09995;
  }
}

.select-wrapper {
  //position: fixed;
  background: white;
  //z-index: 1;
  width: max-content;
  max-width: 400px;
  max-height: 400px;
    min-height: 60px;
  //display: flex;
  //flex: 1;
  overflow: overlay;

    .list-content {
        display: block !important;
        font-size: .86rem;

        &:deep(strong) {
            color: #4caf50;
            font-weight: 600;
        }
    }
}

</style>